.container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-block: 1px solid #fff;
    background: url('../../assets/BaseBG.jpg') center/cover no-repeat;
}

.minionsGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    justify-content: center;
}

.pagination {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.pagination button {
    padding: 8px 16px;
    margin: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    flex-grow: 0;
}

.active {
    background-color: #0056b3; 
  }

  .searchBar {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
  }

/* Media Queries */

@media (max-width: 800px) {
    .pagination {
        justify-content: space-around; 
    }

    .pagination button {
        padding: 6px 12px; 
    }
}

@media (max-width: 500px) {
    .pagination {
        justify-content: space-between; 
    }

    .pagination button {
        padding: 4px 8px; 
        font-size: 12px; 
    }
}