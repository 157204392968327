.navbar {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  background-image: url('../../assets/BG1.png');
  background-size: cover;  
  background-position: center;  
  width: 100%;
  color: #fff;
  
}

.logo {
  width: 400px; 
  height: auto;
  filter: drop-shadow(1px 1px 10px black);
}

.navLinks {
  width: 100%; 
  text-align: center; 
  background-color: black;
  padding-block: 5px;
  margin-bottom: 10px;
}

.navList {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center; 

  margin: 0;
}

.navItem {
  margin: 0 15px; 
  font-family: 'Garamond', serif; 
  font-size: 1.1rem;
}

.navItem a {
  color: #bdc3c7;
  text-decoration: none;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  position: relative;
}

.navItem a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: #ecf0f1;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.navItem a:hover {
  color: #ecf0f1;
  transform: translateY(-1px);
}

.navItem a:hover::after {
  visibility: visible;
  width: 100%;
}

#specialBtn {
  font-weight: bold;
  color: #f39c12;
}

#specialBtnDiscord {
  
  font-weight: bold;
  background-color: #5462F1;
  border-radius: 15px;
  text-decoration: none;
  transition: background-color 0.2s;
}

#specialBtnDiscord:hover {
  background-color: #3643d1; 
  color: white;
  outline: none;
  text-decoration: none;
}

#specialBtnDiscord svg {
  margin-right: 5px; 
  font-size: 20px;
}

.h2Title {
  margin: 0;
  font-size: 25px;
  padding: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #000;
}

.introPar {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 5px;
  background-color: #000;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-inline: 10px;
  padding-block: 5px;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(15px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
@keyframes fadeLogo {
  from {
      opacity: 0;
      transform: translateY(-350px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.logo {
  animation: fadeLogo 2.0s ease-in-out;
}

.navbar {
  animation: fadeIn 1.5s ease-in-out;
}

/* Media Queries */

@media (max-width: 800px) {

  .navLinks {
    width: 100%;
  }

  .navItem{
    margin: 7px;
  }

  .logo {
    width: 300px; 
  }

}

@media (max-width: 480px) {
  .logoContainer {
    margin-bottom: 10px;
  }

  .logo {
    width: 300px; 
  }
  .navList {
    flex-direction: row;
    justify-content: center;
    
   
  }
  .navItem a{
    font-size: 1rem; 
    padding-inline: 0;
  }
  .navItem {
    margin: 7px;
  }
}
