.spinnerContainer {
    position: fixed; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: fit-content;
    max-width: 250px;
    margin-inline: auto;
    z-index: 10000; 
    border-radius: 5px; 
}

.spinnerImg {
    width: 100%;
    object-fit: cover; 
}
