.footer {
    background-image: url('../../assets/BG2.png');
    background-size: cover;  
    background-position: center;  
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: auto;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content p {
    background-color: #000;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}

.content img {
  opacity: 0.9;
  width: 50%;
  border-radius: 20px;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.footer {
  animation: fadeIn 2.5s ease-in-out;
}
