.fcPageContainer {
    font-family: 'Lato', sans-serif;
    color: #fff;
    background: url('../../assets/FCPageBG.png') no-repeat center fixed;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 3px solid white;
    
  }

  .header {
    width: 100%;
  }

  .header img{
    width: 100%;
    border-block: 3px solid white;
  }
  .section {
    width: 80%;
    max-width: 800px;
    margin: 10px 0;
    padding: 20px;
    border-radius: 15px;
    background-color: rgb(255, 255, 255, 0.8);
    -webkit-box-shadow: 0px -2px 26px 0px rgba(0,0,0,1);
    -moz-box-shadow: 0px -2px 26px 0px rgba(0,0,0,1);
    box-shadow: 0px -2px 26px 0px rgba(0,0,0,1);
    animation: fadeInUp 1s ease-out;
  }
  
  .section h2 {
    font-size: 2em;
    color: #000000;
    text-shadow: 2px 2px 4px #e40e0ebb;
    margin: 10px;
  }
  
  .section p {
    line-height: 1.6;
    color: #000;
    font-weight: bold;
  }

  .sectionImg {
    margin-block: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    width: 100%;

  }

  .sectionImg img {
    width: 40%;
    -webkit-box-shadow: 0px -2px 26px 0px rgb(255, 255, 255, 0.8);
    -moz-box-shadow: 0px -2px 26px 0px rgb(255, 255, 255, 0.8);
    box-shadow: 0px -2px 26px 0px rgb(255, 255, 255, 0.8);
  }

  .btnBack {
  background-color: #e2b714;
  color: #fff;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 1s ease-out;
  margin-block: 10px;
  }

  .btnBack:hover {
    background-color: #d10d0d;
    transform: translateY(-2px);
  }
  
  .btnBack:active {
    background-color: #b5890b;
    transform: translateY(0);
  }
  
  .btnBack:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(226, 183, 20, 0.5);
  }

  .altP {
    font-weight: bolder;
    text-decoration: underline;
  }
  
  .links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 7px;
  }
  .links a {
    color: rgba(255, 0, 0, 0.856);
    text-decoration: none;
    font-size: 25px;
    font-weight: bold;
    transition: color 0.3s;
  }
  
  .links a:hover {
    color: red;
    transform: scale(1.05);
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  