.card {
    background: url('../../assets/Frame.png') center / contain;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
    padding-inline: 10px;
    margin: 20px;
    max-width: 350px;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .card:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.9);
  }

  .fcLogo {
    width: 90%;
    border-radius: 15px;
    border: 1px solid #000;
  }
  
  .title {
    font-size: 1.5em;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  
  .description {
    font-size: 1em;
    color: #000;
    margin-bottom: 10px;
  }
  
  .recruitButton {
    background-color: none;
    color: #000;
    border: 1px solid #000;
    padding: 5px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.4s;
    margin-bottom: 35px;
  }
  
  .recruitButton:hover {
    background-color: #0056b3;
    color: #fff;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
  