.pageLayout {
    display: flex;
    font-family: 'Garamond', serif; 
    border-block: 1px solid #fff;
  }
  
  .newsFeed {
    flex: 1;
    color: #fff;
    background-color: #152433;
    padding: 20px;
    margin: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    border-bottom: 2px solid goldenrod;
    padding-bottom: 10px;
    text-align: center;
  }
  
  .newsItem {
    margin-top: 20px;
  }
  
  .newsItem h2 {
    color: goldenrod;
  }
  
  .newsItem p {
    color: #fff;
    font-size: 16px;
    margin-top: 5px;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.newsFeed {
    animation: fadeIn 3.5s ease-in-out;
}