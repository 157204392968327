.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

h2 {
    text-align: center;
}

.modal {
    background: #333; 
    color: #fff; 
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); 
    width: 90%; 
    max-width: 30%; 
    position: relative;
    animation: modalEntrance 0.3s ease-out forwards; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
}

.closeButton {
    width: 15%;
    border: none;
    background: none;
    color: #ccc; 
    font-size: 24px;
    cursor: pointer;
}

.closeButton:hover {
    color: #fff; 
}

label {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    font-size: 0.9rem;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-top: 2px;
    text-align: center;
    background: #222; 
    border: 1px solid #555; 
    color: #ddd; 
    border-radius: 5px;
    
}

input[type="text"]:hover,
input[type="email"]:hover,
input[type="password"]:hover,
input[type="file"]:hover {
    background: #1e1e1e; 
    border-color: #777;
}

.modal button {
    background-color: #f39d12a1; 
    color: rgb(255, 255, 255);
    border: none;   
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
}

.modal button:hover {
    background-color: #f39c12; 
}

@keyframes modalEntrance {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 1000px) {
    .modal {
        max-width: 60%;
    }
    form {
    width: 80%;
    }
}

@media (max-width: 500px) {

    .modal {
        max-width: 60%;
    }
    form {
    width: 80%;
    }
   
}
