.card {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #e4dddd;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-align: center;
    position: relative;
}

.card:hover {
    transform: scale(1.05); 
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); 
}

.bookmark {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    font-size: 30px;
    }
    
    .bookmark:hover {
        transform: scale(1.05);
        cursor: pointer;
    }

.icon {
    
    width: 50px;
    height: 50px;
}

.details, p {
    text-align: center;
    font-size: 1em;
    color: rgb(24, 24, 24);
}

.title {
    margin-top: 10px;
    color: #333;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.card {
    animation: fadeIn 1.5s ease-in-out;
}