.card {
    border: 1px solid #ddd;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
    background: #f9f9f9;
    width: 50%;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.title {
    margin: 15px 0;
    font-size: 1.5rem;
    color: #1a237e; 
    text-align: center;
    animation: fadeIn 0.8s ease-out;
}

.date {
    color: #666;
    font-size: 0.85rem;
    margin-bottom: 10px;
    text-align: center;
}

.link {
    text-decoration: none;
    color: #3f51b5;
    border-radius: 50px;
    padding: 3px ;
    margin-top: 5px;
    transition: transform 0.3s ease;
    display: block;
    text-align: center;
    margin-inline: auto;
}

.link:hover {
    transform: translateY(-3px) scale(1.05);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 1200px) {
    .card {
        width: 60%; 
    }
}

@media (max-width: 800px) {
    .card {
        width: 60%;
    }
}

@media (max-width: 500px) {
    .card {
        width: 60%;
    }
}



.card {
    animation: fadeIn 1.5s ease-in-out;
}

