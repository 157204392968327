.msgBoardContainer {
  border-block: 1px solid #fff;
  background: url('../../assets/tryBG.png') center/cover no-repeat;
}
.msgBoard {
  
  
    border-radius: 10px;
    padding: 20px;
    margin: 20px auto;
    width: 70%;
    max-width: 1000px;
    
    color: #f8f8f8;
    
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .msgBoardContainer h2 {
    color: #fff;
    width: 100%;
    background-color: #000;
    padding-block: 2px;
  }
  
  .input, .button {
    padding: 12px 15px;
    border-radius: 4px;
    border: 2px solid #4a4e69;
    margin: 0 auto;
    margin-block: 15px;
    width: 100%;
    box-sizing: border-box; 
  }
  
  .input {
    background-color: transparent;
    color: #f8f8f8;
    width: 100%;
  }
  
  .button {
    background-color: #4a4e69;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 50%;
  }
  
  .button:hover {
    background-color: #373b53;
  }
  
  .messagesList {
    list-style-type: none;
    min-height: 150px;
    overflow-y: auto;
    padding: 0;
    margin: 5px;
    width: 100%;
  }
  
  .message {
    background-color: #27293d;
    border: 1px solid #373b53;
    padding: 10px;
    margin-top: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    text-align: center;
    
  }
  
  .error {
    color: #e63946; 
    margin-top: 10px;
  }
  
  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.msgBoardContainer {
    animation: fadeIn 2.5s ease-in-out;
}
  
  