.sidebarClosed, .sidebarOpen {
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    transition: left 0.5s ease;
    overflow: hidden;
    z-index: 1000;
    border-right: 3px solid #f8f8f8;
    box-shadow: 5px 0 15px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
}

.sidebarClosed {
    left: -182px; 
    width: 180px; 
    background: linear-gradient(180deg, #090913 0%, #343467 100%);
    opacity: 0.5;
}

.sidebarOpen {
    left: 0;
    width: 180px;
    background: linear-gradient(180deg, #090913 0%, #1a1a3d 100%);
    opacity: 1;
}

.sidebarOpen span {
    visibility: visible;
    opacity: 1;
    transition: width 1.5s , opacity 0.5s ease;
    width: 0;
}

.sidebarClosed span {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.sideImg {
    height: auto;
    width: 150px;
    margin-inline: 15px;
    margin-top: 5px; 
}

.toggleButton {
    position: fixed;
    top: 40px; 
    left: 50px;
    background: #2a2e40;
    border: 1px solid white;
    color: white;
    font-size: 15px;
    cursor: pointer;
    padding: 10px;
    border-radius: 0 5px 5px 0;
    transition: left 0.5s ease;
    z-index: 1000;
}

.title h4{
    color: #fff;
    font-family: 'Garamond', serif;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    font-size: 24px;
    margin-block: 5px;
    text-align: center;
}

.titleHome h4{
    color: #fff;
    font-family: 'Garamond', serif;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    font-size: 18px;
    margin-block: 10px;
    text-align: center;
    cursor: pointer;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li a {
    color: #ddd;
    text-decoration: none;
    padding: 10px;
    display: flex;
    align-items: center;
    transition: color 0.3s, transform 0.3s;
}

li a:hover {
    color: #fff;
    transform: translateX(10px);
    cursor: pointer;
}

li img {
    width: 25px;
    margin-right: 10px; 
    transition: transform 0.5s ease, width 0.5s ease;
}

.sidebarClosed li a span, .sidebarClosed .title, .sidebarClosed li img {
    display: none;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
  }

  .toggleButton {
    animation: fadeIn 1.5s ease-in-out;
  }


