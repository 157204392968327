@font-face {
  font-family: 'Font.FF';
  src: url('./customFont/OPTIEngeEtienne.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

html, body {
  
  font-family: 'Font.FF', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}

#root {
  background:
  linear-gradient(180deg, #090913 0%, #181831 100%);
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
  margin: 0;
  padding: 0;
}
