.container {
    border-block: 1px solid #fff;
    text-align: center;
    margin-block: auto;
    padding: 20px;
    
    background-color: #1a1a3d;
    color: #ffffff;
}

.title {
    font-size: 28px;
    color: #f39c12;
    margin-bottom: 20px;
}

.text {
    font-size: 18px;
    color: #fffacd;
    margin-bottom: 15px;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.container {
    animation: fadeIn 3.5s ease-in-out;
}
