.pageImg{
  width: 100%;
  height: auto;
  filter: drop-shadow(1px 1px 30px black);
}

.flipbook {
  width: 500px;
  height: 700px;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  
}

.resetBtn {
  border: 1px solid white;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
}

.resetBtn:hover {
  border: 1px solid black;
  background-color: white;
  color: black;
  transition: 1s;
  cursor: pointer;
}



