.dashboardContainer {
    border-block: 1px solid #fff;
    
    color: #fff;
    padding-block: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url('../../assets/BaseBG.jpg') center/cover no-repeat;
}

.dashboardContainer h1 {
    background-color: #000;
    padding-block: 2px;
    width: 100%;
    text-align: center; 
}

.userCard {
    background:
    linear-gradient(180deg, #090913 0%, #181831 100%);
    color: #fff;
    box-shadow: 0 8px 8px 8px  rgba(242, 242, 243, 0.1);
    transition: 0.3s;
    width: 40%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin: 20px;
    
}

.avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 15px;
    object-fit: cover;
}

.userInfo {
    padding: 2px 16px;
}

.userInfo h2 {
    margin: 0;
}

.userInfo p {
    color: #fff;
}

.savedTitle {
    background-color: #000;
    padding-block: 2px;
    width: 100%;
}

.collectibleItem {
    background: #d4d3d3ee;
    height: 150px;
    padding: 15px;
    margin: 10px;
    border-radius: 20px;
    border-left: 5px solid #ff0000;
    border-right: 5px solid #ff0000;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 20%; 
    max-width: 20%;
}

.collectibleItem:hover {
    background: #f0f0f0;
    transform: scaleX(1.05);
}

.collectibleName {
    text-align: center;
    font-size: 18px;
    color: #333;
    font-weight: bold;
}

.collectibleDescription {
    font-size: 14px;
    color: #555;
    margin-top: 10px;
    margin-bottom: 0;
}

.itemIcon {
    width: 40px;
    
}
.itemsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;  
    flex-wrap: wrap;
    width: 100%;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.dashboardContainer {
    animation: fadeIn 3.5s ease-in-out;
}

/* Media Query */
@media (max-width: 800px) {
    .userCard {
        width: 50%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 15px;
    }

    

    .dashboardContainer {
        margin: 10px;
    }
    .dashboardContainer h1{
        font-size: 30px;
    }

    .collectibleItem {
        padding: 25px;
    }

}
