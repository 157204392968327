.Toastify__toast-container {
    width: 200px; 
    padding: 0;
    
  }
  
 
  .Toastify__toast {
    border-radius: 8px;
    padding: 16px 24px;
    margin-bottom: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    font-size: 14px;
    font-weight: 500;
  }
  
 
  .Toastify__toast--success {
    background-color: #0a0a0a; 
    color: white;
  }
  
  .Toastify__toast--error {
    background-color: #f44336;
    color: white;
  }
  
  
  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
  
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0;
    }
  }
  
  .Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
    animation-name: slideInLeft;
  }
  
  .Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
    animation-name: fadeOut;
  }
  
