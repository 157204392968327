.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    border-block: 1px solid #fff;
    background: url('../../assets/BaseBG.jpg') center/cover no-repeat;
  }
  
  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .successMessage {
    font-size: 24px;
    color: #0091ea;
  }