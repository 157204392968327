.container {
    border-block: 1px solid white;
    display: flex;
    flex-direction: row;
    align-items: flex-start ;
    width: 100%;
    background: url('../../assets/BaseBG.jpg') center/cover no-repeat;
}

.newsContainer, .maintenanceContainer {
    width: 50%;  
    display: flex;
    flex-direction: column;
    align-items: center;
}

.searchContainer {
    background: url('../../assets/searchBg.png') center/cover no-repeat;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 350px;
    border-block: 1px solid white;
}

.searchContainer h2 {
    color: #fff;
    background-color: #000;
    width: 100%;
    padding-block: 2px;
}

.searchContainer input[type="text"] {
    width:50%;
    margin: 5px;
}

.searchContainer button {
    border: 1px solid #fff;
    color: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    background-color: #000;
}
.searchContainer button:hover {
    border: 1px solid #000;
    color: #000;
    background-color: #fff;
    transition: 1.5s;
}

.searchResultsContainer {
    background: url('../../assets/cardBG.gif') center/cover no-repeat;
    background-color: #000; 
width: 100%;
display: flex;
justify-content: center;
color: #fff;
}

.headTitle {
    background-color: #000;
    padding-block: 2px;
    width: 100%;
    color: #fff;
    margin-bottom: 0;
    font-size: 30px;
    margin-bottom: 10px;
}
.lodestoneCharCard {
    
    
    display: flex;
    align-items: center;
    background-color: none;
    border-radius: 8px;
    padding: 20px;
    margin-block: 20px;
    width: 60%;
  }
  .lodestoneCharInfo {
    width: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
  .lodestoneCharInfo p {
    filter: drop-shadow(0px 1px 1px #000);
    color: #fff;
    margin: 8px;
  }

  .portrait {
   width: 100%;
   opacity: 0.9;
   max-width: 300px;
   border-radius: 30px;
   filter: drop-shadow(1px 1px 5px white);
  }

  .avatar {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #ddd;
  }
  #disclaimer {
    filter: drop-shadow(0px 1px 1px #000);
    color: rgba(255, 0, 0, 0.966);
  }
  .sectionContainer {
    border: 1px solid #fff;
    border-radius: 15px;
  }

/* Media Query */
@media (max-width: 800px) { 

    .container {
        display: flex;
        flex-direction: column;
    }

    .newsContainer, .maintenanceContainer {
        width: 100%; 
        
    }
    .avatar {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
    }
    .lodestoneCharCard {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.searchResultsContainer, .searchContainer {
    animation: fadeIn 3.5s ease-in-out;
}